import { FC, useRef } from "react"
import './index.less'
import Logo from "@/assets/logo.png";
import { Button } from "antd";

interface IProps{
    contactRef:any
}
const Part1: FC<IProps> = ({contactRef})=>{

    const toContact=()=>{
        contactRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    return <div className="home-screen-container">
        <div className="mask"/>
        <img src={Logo} className="logo"></img>
        <section className="home-screen-content">
            <h1 className="title">More than a facilitator,</h1>
            <h1 className="title"> we’re your dedicated service partner—</h1>
            <h1 className="title">bringing tailored solutions and seamless support every step of the way</h1>
            <Button type="primary" className="btn" onClick={toContact}>LEARN MORE</Button>

        </section>   
    </div>
}
export default Part1;