import { FC } from "react"
import './index.less'
import img1 from './1.png'
import img2 from './2.png'
import img3 from './3.png'

const Part9: FC = ()=>{


    return <section className="home-part6-container">
        <h1 className="title">Our Services</h1>
        <section className="content">
          <div className="block">
            <div className="item">
              <div className="mask"/>
              <div className="content">
                <img src={img1} className="img"></img>
                <div className="sub-title">Full coverage of study abroad services</div>
                <div className="text">We always believes that the study abroad application is not the end of the study abroad service. It's just the beginning of studying abroad.
  Our service chain covers the whole study abroad before, during and after, guarding every customer from going abroad to returning home.
  Full coverage of study abroad services</div>
              </div>
            </div>
            <div className="item">
              <div className="mask"/>
              <div className="content">
                <img src={img2} className="img"></img>
                <div className="sub-title">1-on-1 Personalized Counseling</div>
                <div className="text">Our study abroad experts are graduates from elite international institutions, offering guidance rooted in shared experiences and understanding. Each advisor brings extensive knowledge of the application process, ensuring direct, seamless support without the delays of cross-department coordination.</div>
              </div>
            </div>
            <div className="item">
              <div className="mask"/>
              <div className="content">
                <img src={img3} className="img"></img>
                <div className="sub-title">Custom
                optional services</div>
                <div className="text">The menu service mode is implemented for the first time.
Purpose one: Transparency of service content.
The second purpose: to give customers exclusive customized solutions. All the service content can be selected, freely customized, not to spend a penny.</div>
              </div>
            </div>
          </div>
        </section>
        <div className="background">
          <div className="mask"/>
        </div>
      </section>
}
export default Part9;