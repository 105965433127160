import { FC } from "react"
import './index.less'
import image from './2.jpg'
import {CheckOutlined} from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

interface IProps{
  contactRef:any
}
const Part2: FC<IProps> = ({contactRef})=>{

  const toContact=()=>{
    contactRef.current.scrollIntoView({ behavior: 'smooth' });
}
    return <div className="home-window-container">
      <section className="left">
        <h1 className="title">Our Concept -- Empathy</h1>
        <div className="desc">
        Empathy sees through students' eyes – understanding their needs to deliver solutions that truly support them.
        </div>
        <Button type="primary" className="btn" onClick={toContact}>DISCOVER MORE</Button>
        <section className="block">
          <div className="item">
            <div className="label">
              <CheckOutlined className="icon"/> 
              <span>Self-motivation</span>
            </div>
            <div className="desc">
              Helping customers solve their needs is not as good as <b>providing solutions to their needs. </b>
            </div>
          </div>
          <div className="item">
            <div className="label">
              <CheckOutlined className="icon"/> 
              <span>Independence</span>
            </div>
            <div className="desc">
              In the service relationship, let the customer control the initiative. <b>Customize your own service system.</b>
            </div>
          </div>
          <div className="item">
            <div className="label">
              <CheckOutlined className="icon"/> 
              <span>Self-consciousness</span>
            </div>
            <div className="desc">
              Trying means <b>breaking the old approaches</b> again and again and experiencing new ones.
              Experience, feel new feelings, know new cognition.
            </div>
          </div>
        </section>
      </section>
      <section className="right">
        <img className="image" src={image}></img>
      </section>
          
      </div>
}
export default Part2;