import { FC } from "react"
import './index.less'
import Logo from "@/assets/logo.png";
import { Button } from "antd";
interface IProps{
    contactRef:any
}
const Part1: FC<IProps> = ({contactRef})=>{
    const toContact=()=>{
        contactRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    return <div className="home-part4-container">
        <div className="mask"/>
        <section className="home-part4-content">
            <div className="title"><b>Empathy Group </b>does not exist to</div>
            <div className="title">solve the current supply and</div>
            <div className="title">demand relationship.</div>  
            <Button type="primary" className="btn" onClick={toContact}>DISCOVER MORE</Button>
        </section>   
    </div>
}
export default Part1;