import { FC } from "react"
import './index.less'
import Pad from './assets/pad.png'
import Phone from './assets/phone.png'
import desktop from './assets/desktop.png'

import icon1 from '@/assets/icon/10.png'
import icon2 from '@/assets/icon/6.png'
import icon3 from '@/assets/icon/14.png'
import { Col, Row } from "antd"
import logo from '@/assets/logo.png'

interface IProps{
  contactRef:any
}
const Part8: FC<IProps> = ({contactRef})=>{
    return <section className="home-part8-container">
      <div className="title">Contact US</div>

      <div className="content" ref={contactRef}>
        <section className="left">
          <img src={logo} className="logo"/>
          <div className="detail"> 
            <div className="company">Empathy Global Ptd. Ltd.</div>
            <div className="address">Brisbane, Australia</div>
            <div className="desc">Comprehensive Study Abroad Service Platform - Joint & System Introduction</div>
          </div>
        </section>
        <section className="right">
            <div className="media">
              <img src={icon1} className="icon"/>
              <div className="detail"> 
                <div className="label">Wechat</div>
                <div className="value">i1lusionist</div>
              </div>
            </div>
            <div className="media">
              <img src={icon2} className="icon"/>
              <div className="detail"> 
                <div className="label">Email Address</div>
                <div className="value">mkt001@empathytech666.com</div>
              </div>
            </div>
            <div className="media">
              <img src={icon3} className="icon"/>
              <div className="detail"> 
                <div className="label">Phone Number</div>
                <div className="value">+86-17717571223</div>
              </div>
            </div>
        </section>
      </div>
      </section>
}
export default Part8;