import { FC } from "react"
import './index.less'
import { Row ,Col} from "antd";
import icon1 from '@/assets/icon/1.png';
import icon2 from '@/assets/icon/2.png';
import icon3 from '@/assets/icon/3.png';
import icon4 from '@/assets/icon/4.png';
import icon7 from '@/assets/icon/7.png';
import icon8 from '@/assets/icon/8.png';
import icon9 from '@/assets/icon/9.png';
import icon13 from '@/assets/icon/13.png';
import icon16 from '@/assets/icon/16.png';
import icon17 from '@/assets/icon/17.png';
import icon19 from '@/assets/icon/19.png';


import { BarChartOutlined, DesktopOutlined, FormOutlined, MobileOutlined, UndoOutlined, UnorderedListOutlined } from '@ant-design/icons';
const Part3: FC = ()=>{


    return <section className="home-function-conatiner">
    <h1 className="title">Empathy Education`s Advantage </h1>
    <div className="home-function-content">
        <div className="mask"></div>
        <div className="left">
          <div className="title">ABOUT US</div>
          <div className="sub-title">History of cooperation</div>
          <img src={icon9} className="icon" style={{height:27}}/>
          <div className="text">Soochow University, Taiwan</div>
          <img src={icon17} className="icon"/>
          <div className="text">Korea University</div>
          <img src={icon7} className="icon"/>
          <div className="text">Han yang University</div>
          <img src={icon2} className="icon" style={{width:45}}/>
          <div className="text">Beijing Institute of Technology</div>
          <img src={icon4} className="icon"/>
          <div className="text">Agriciltural University of Thailand</div>
          <img src={icon3} className="icon"/>
          <div className="text">Singapore Institute of Management</div>
        </div>
        <div className="content">
          <div className="sub-title">Empathy is our name and our weapon. </div>
          <Row gutter={[16, 24]} className="item-container">
            <Col xs={12} md={12} lg={8} className="item">
              <Row className="item-label">
                <img src={icon16} className="item-icon" style={{height:45}}/>
                <span style={{marginLeft:10}}>Matrix Operation</span>
              </Row>
              <div className="item-content">
                <div className="item-content-row">international students group:500+</div>
                <div className="item-content-row">overseas students users:20w+</div>
                <div className="item-content-row">public account platform:10+</div>
              </div>     
            </Col>
            <Col xs={12} md={12} lg={8} className="item">
              <Row className="item-label">
                <img src={icon19} className="item-icon" style={{height:40}}/>
                <span style={{marginLeft:10}}>Partnerships experience</span>
              </Row>
              <div className="item-content">
                <div className="item-content-row">Effective relationships and experience with overseas partners.</div>
              </div>     
            </Col><Col xs={12} md={12} lg={8} className="item">
              <Row className="item-label">
                <img src={icon3} className="item-icon" style={{height:45}}/>
                <span style={{marginLeft:10}}>Product agency</span>
              </Row>
              <div className="item-content">
                <div className="item-content-row">Complete overseas student service product agent and system,including learning end, life end.</div>
              </div>     
            </Col>

            <Col xs={12} md={12} lg={8} className="item">
              <Row className="item-label">
                <img src={icon13} className="item-icon" style={{height:45}}/> 
                <span style={{marginLeft:10}}>Brand effect IP</span>
              </Row>
              <div className="item-content">
                <div className="item-content-row">6 years of experience in cooperation with different industries.</div>
              </div>     
            </Col>
            <Col xs={12} md={12} lg={8} className="item">
              <Row className="item-label">
                <img src={icon1} className="item-icon" style={{height:45}}/>
                <span style={{marginLeft:10}}>Complete product</span>
              </Row>
              <div className="item-content">
                <div className="item-content-row">The "incubation" experience from 0 to 1</div>
              </div>     
            </Col><Col xs={12} md={12} lg={8} className="item">
              <Row className="item-label">
                <img src={icon8} className="item-icon" style={{height:40}}/>
                <span style={{marginLeft:10}}>Marketing experience </span>
              </Row>
              <div className="item-content">
                <div className="item-content-row">Long-term and in-depth cooperation with many brands favored by international students. And made remarkable achievements in the cooperation.</div>
              </div>     
            </Col>

          </Row>
        </div>
    </div>
    </section>
}
export default Part3;