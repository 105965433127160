import { FC } from "react"
import './index.less'
import Pad from './assets/pad.png'
import Phone from './assets/phone.png'
import desktop from './assets/desktop.png'

import img1 from './4.png'
import img2 from './5.jpg'
import { Col, Row } from "antd"

const Part9: FC = ()=>{


    return <section className="home-part5-container">
        <section className="top">
          <div className="left">
            Our FrameWork
          </div>
          <div className="right">
            <div>The overseas study industry suffers from information gaps due to imbalances in pricing and service details. </div>
            <div>It's time to move beyond traditional intermediaries and lead a new wave of transparency and value.</div>
            <div>Our model connects <b>"service providers – platform supervision – users"</b>, empowering users to make smart choices in cost-effective, competitive services and products.</div>
          </div>
        </section>
        <section className="content">
          <div className="title">"We are constantly shaping..."</div>
          <div className="block">
            <div className="item item-one">
              <div className="mask"/>
              <h2 className="text">Front-end service provider</h2>
            </div>
            <div className="item item-two">
              <div className="mask"/>
              <h2 className="text">Mid-range service provider</h2>
            </div>
            <div className="item item-three">
              <div className="mask"/>
              <h2 className="text">End service provider</h2>
            </div>
          </div>
        </section>
        <img src={img1} className="arrow"></img>
        <section className="foot">
          <img src={img2} className="logo"></img>
          <section className="content">
            <div className="text">Media Network</div>
            <div className="text">WeChat Community</div>
            <div className="text">International Student Organization</div>
          </section>
        </section>
      </section>
}
export default Part9;