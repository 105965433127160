import { FC, useRef } from "react"
import Part1 from './part1'

import Part2 from './part2'
import Part3 from './part3'
import Part4 from "./part4"
import Part5 from "./part5"
import Part6 from "./part6"
import Part7 from "./part7"

import Part8 from "./part8"
const Home: FC = ()=>{
    const contactRef = useRef(null);
    return <div>
            <Part1 contactRef={contactRef}/>
            <Part2 contactRef={contactRef}/>
            <Part3 />
            <Part4 contactRef={contactRef}/>
            <Part5 />
            <Part6 />
            <Part7 contactRef={contactRef}/>
            <Part8 contactRef={contactRef}/>
        </div>
}
export default Home;