import { FC } from "react"
import './index.less'
import { Button } from "antd"
interface IProps{
  contactRef:any
}
const Part7: FC<IProps> = ({contactRef})=>{

  const toContact=()=>{
    contactRef.current.scrollIntoView({ behavior: 'smooth' });
}
    return <section className="home-part7-container">
        <div className="mask"/>
        <section className="content">
          <div className="title">Our Cooperation</div>
          <div className="title-desc">platform system co-creation</div>
          <div className="block">
            <div className="item item-one">
              <div className="mask"/>
              <div className="content">
                <div className="sub-title">First-level co-creation system</div>
                <div className="text"><b>Merge into Empathy Group's upstream and downstream. </b>
                Unified market flow channels to achieve self-transformation within the system.</div>
                <Button type="primary" className="btn" onClick={toContact}>DISCOVER MORE</Button>
              </div>
            </div>
            <div className="item item-two">
              <div className="mask"/>
              <div className="content">
                <div className="sub-title">Secondary co-creation system</div>
                <div>
                  <div className="text"><b>Overseas product design, operation, resale.</b> </div>
                  <div className="text">Fit the transformation system of Empathy Group, enrich the service chain of Empathy Group.</div>
                </div>
                <Button type="primary" className="btn" onClick={toContact}>DISCOVER MORE</Button>

              </div>
            </div>
            <div className="item item-three">
              <div className="mask"/>
              <div className="content">
                <div className="sub-title">Three-level co-creation system</div>
                <div>
                  <div className="text"><b>Share Empathy Group's users.</b></div>
                  <div className="text">On the premise of non-conflict business formats, the study abroad service ecology is replenishment.</div>
                </div>
                
                <Button type="primary" className="btn" onClick={toContact}>DISCOVER MORE</Button>
              </div>

            </div>
          </div>
        </section>

      </section>
}
export default Part7;